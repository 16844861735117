.serverhome-body-outer {
  /* display: flex; */
  position: relative;
  width: 100%;
  /* height: 100vh; */
  background-size: cover;
}

.server-home-model-function-select-con-model {
  height: 600px;
  width: 290px;
  margin: 0 auto;
  /* padding: 10px; */
  margin-top: 50px;
  border: 5px solid rgba(163, 163, 163, 0.383);
  border-radius: 20px;
  box-shadow: 1px 1px 6px gray;
}
.server-home-model-function-select-con-model-block {
  height: 600px;
  width: 290px;
  margin: 0 auto;
  /* padding: 10px; */
  margin-top: 50px;
  /* border: 5px solid rgba(163, 163, 163, 0.383); */
  border-radius: 20px;
  /* box-shadow: 1px 1px 6px gray; */
}
.server-home-model-function-select-con-model .function-select-context {
  /* margin-top: 10px; */
  border-radius: 10px;
  /* border:rgb(223, 223, 223) 1px solid; */
  text-align: justify;
  padding: 10px;
  margin-top: 30px;
  height: 170px;
  font-size: small;
  /* margin-top:10px; */
}

.server-home-model-function-select-con-model .function-select-img-outer {
  width: 70%;
  display: flex;
  justify-self: center;
  align-items: center;
  margin: 0 auto;
  height: 200px;
  /* margin: 10px auto; */
}

.server-home-model-function-select-img-outer {
  width: 100%;
}

.server-home-model-function-select-title {
  width: 60%;
  text-align: center;
  font-size: xx-large;
  font-weight: 700;
  margin: 30px auto;
}

.function-select-button-out {
  width: 100%;
  /* display: flex; */
}

.function-select-button {
  width: 95%;
  height: 40px;
  background-color: lightseagreen;
  color: white;
  /* margin: 10px auto; */
  border-radius: 10px;
}

.server-home-model-function-select-con-model-outside {
  width: 100%;
  /* float: inherit; */
  display: flex;
  border: 3px solid rgba(128, 128, 128, 0.413);
  border-radius: 0 3% 3% 3%;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.function-select-up-down {
  width: 100%;
}

.serverhome-body-outer-up-down {
  /* position: relative; */
  width: 100%;
  /* height: 100vh; */
  /* background-size: cover; */
}
.function-select-up-down-title {
  width: 370px;
  height: 50px;
  text-align: center;
  font-size: large;
  padding: 12px;
  background-color: lightseagreen;
  margin-top: 20px;
  /* border: 3px solid rgba(128, 128, 128, 0.413); */
  font-weight: bold;
  border-radius: 10px 10px 0% 0%;
  color: white;
}
#server-home-model-function-select-con-model-diskge {
}
