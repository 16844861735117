.graph-tutor {
    width: 90%;
}

.graph-tutor img {
    width: 100%;
}

.tutorial-body {

    position: relative;
    width: 100%;
    /* height: 100vh; */
    background-size: cover;
}

.tutorial-connect-text_title {
    width: fit-content;
    margin: 50px auto;
    font-size: large;
    font-weight: 700;
}

.tutorial-connect-text_timeline {
    width: 100%;
    margin: 0 auto;
}

.title-source {
    width: 100%;
    font-size: xx-large;
    font-weight: 700;
    text-align: center;

}

.title-source-button {
    width: 80%;
    margin: 20px auto;
    padding: 10px;
    border: dotted lightgray 5px;
    height: fit-content;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.title-source-button Button {
    width: 20%;
    color: white;
    background-color: rgb(70, 190, 178);
    border-radius: 15px;
    height: 70px;
    border: 1px solid rgb(70, 190, 178);
    font-size: large;
    font-weight: 700;
    margin: 5%;

}

.title-source-button Button :hover {}

.tutrial-img-outline {
    width: 100%;

}

.tutrial-img {
    width: 70%;
    margin: 0 auto;
}

.tutorial-tree {
    width: fit-content;
    /* padding: 40px; */
    /* margin: 20px auto; */
}

.tutorial-tree-out {
    width: 90;
    border-radius: 10px;
    /* border: 1px rgba(3, 3, 3, 0.383) solid; */
    /* margin:50px 0; */
}

.tutorial-Anchor-outer {
    margin-right: -100px;
    width: fit-content;
    float: right;
    position: sticky;
    top: 100px; /* Adjust this value as needed */
    margin-right: 20px;
}

.tutorial-Anchor-outer-position {
    margin-top: 100px;
}

.tutorial-Typography {
    width: 100%;
}

.tutorial-content {
    display: flex;
    align-items: flex-start;
}

.tutorial-main {
    flex: 1;
}