.about-body {
  width: 100%;
}

.about-connect-text {
  width: 100%;
  /* margin: 100px auto; */
}

.about-connect-text_title {
  width: 20%;
  margin: 0 auto;
}

.about-connect-text_title-text-left {
  width: 50%;
  float: left;
  border-bottom: solid 2px #08f3d4;
  /* height: 2px; */
}

.about-connect-text_title-text-right {
  width: 50%;
  float: right;
  border-bottom: solid 2px #08f3d4;
  /* height: 2px; */
}

.about-connect-text_title-text {
  font-size: xx-large;
  font-weight: 600;
  width: fit-content;
  margin: 0 auto;
}

.about-connect-text-and-map {
  margin: 60px;
}

.about-connect-text-items {
  width: 50%;
  height: 700px;
  float: left;
}

.map-w3ls {
  width: 50%;
  height: 500px;
  float: right;
}

.map-item {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.about-connect-text-position {
  margin: 50px;
}

.about-connect-text-position-title {
  font-size: 20px;
  color: #08f3d4;
}

.about-connect-text-position-con {
  font-size: 16px;
  margin: 10px;
}

.contact-about {
  width: max-content;
  font-size: 150px;
  margin: 10%;
  padding: 50px;
  border-radius: 10px;
  border: lightgray dotted 5px;
}

.about-connect-body {
  /* display: flex; */
  position: relative;
  width: 100%;
  /* height: 100vh; */
  /* background-size: cover; */
  /* justify-content: center; */
  /* align-items: center; */
}

.UserOutlined-text {
  font-size: 20px;
  margin: 0 auto;
  text-align: center;
}

.professor-graph {
  width: 90%;
  margin: 10px auto;
}

.professor-graph-con {
  width: 100%;
  border-radius: 5px;
}

.professor-graph text {
  margin: 0 auto;
  width: fit-content;
}

.professor-graph-left {
  width: 49%;
  float: left;
  display: flex;
}

.professor-graph-right {
  width: 50%;
  float: left;
  /* padding: 0px 0; */
}

.professor-details {
  /* text-align: center; */
  /* font-size: large; */
  font-weight: 600;
}

/* .about-contact-title{
  color: #1980FF !important;
} */
.user-out-lined-icon {
  margin-right: 5px;
}

.professor-graph-left-add {
  width: 45%;
  float: left;
  display: flex;
}
