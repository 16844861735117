.Resources-body {
  height: 100vw;
  width: 100%;
}

.Resources-depart {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* height: 700px; */
  padding-top: 100px;
}

.Resources-departs {
  width: 33%;
  /* margin: 0 auto; */
}

.Resources-departs-title {
  /* text-align:center; */
  margin-bottom: 20px;
  font-size: x-large;
  font-weight: 600;
  color: cadetblue;
}
