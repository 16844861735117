.result-chart {
  float: left;
}

.result-chart {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-body-title {
  width: fit-content;
  margin: 50px auto;
  font-size: xx-large;
  font-weight: 700;
}

.result-body-inline {
  width: 100%;
}

.left-float-main-table {
  float: left;
  width: 57%;
  margin: 30px 1%;
}

.left-float-main-main {
  float: left;
  width: 40%;

  margin-top: 30px;
  padding: 0px 0;
}

.result-body-Id {
  width: 100%;
  margin: 10px auto;
  padding: 20px;
  background-color: #cce5ff;
  border-radius: 10px;
  font-size: x-large;
  font-weight: 600;
}

.left-float-main-main-title {
  margin: 10px auto;

  margin-top: 0px;
  padding: 13px;
  text-align: center;
  width: 100%;
  font-size: large;
  font-weight: 600;
  border-radius: 5px;
  background-color: #d1ecf1;
}

.left-float-main-main-radar {
  margin: px 0;
  width: 100%;
  border-radius: 10px;
  border: rgb(212, 212, 212) 1px solid;
}

th .ant-table-cell {
  /* background-color: green !important; */
}

th.columns-color {
  background-color: #d1ecf1 !important;
}

.columns-button {
  border-radius: 5px;
}

.radar-tips {
  width: 100%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #d1ecf1;
  padding: 10px;
}

.metrics-table {
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  background-color: #fff;
  overflow-x: auto; /* Allow horizontal scrolling on small screens */
}

.metrics-table table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  /* Change table-layout to auto for responsive columns */
  table-layout: auto;
}

.metrics-table th,
.metrics-table td {
  padding: 4.8px;
  text-align: center;
  border: 1px solid #e8e8e8;
  word-break: break-word; /* Break long words to fit into cells */
}

.metrics-table th {
  background-color: #d1ecf1;
  font-weight: 600;
}

.metrics-table td {
  background-color: #fff;
}
