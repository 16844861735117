.homepage-body {
  width: 100%;
  /* padding-bottom: 50px; */
}

.homepage-works-instro {
  width: 80%;
  border: 0px solid rgb(182, 181, 181);

  margin: 60px auto;
  border-radius: 20px;
  text-align: center;
  padding-top: 60x;
}

.homepage-works-show {
  width: 850px;
  border: 1px solid rgb(182, 181, 181);
  margin: 60px auto;
  border-radius: 20px;
  padding: 10px;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-works-show-img {
  width: 50%;
}

.homepage-works-show-intro {
  padding: 20px;
  width: 50%;
  border: dashed 1px gray;
  border-radius: 10px;
  margin: 10px;
  font-size: 18px;
}

.homepage-works-show-intro div {
  margin: 10px;
}

.img-homepage {
  width: 100%;
}

.homepage-works-instro-text {
  margin: 40px;
  margin-top: 60px;
}

.homepage-works-instro-text-title {
  font-size: xx-large;
  font-weight: 600;
  width: fit-content;
  margin: 0 auto;
}

.homepage-works-instro-text-title-outsider {
  width: 100%;
}

.homepage-works-instro-text-title-line-up {
  height: 10px;
  width: 200px;

  margin: 0 auto;
}

.homepage-works-instro-text-title-line-up div {
  width: 50%;
  float: left;
  border-bottom: solid 2px #08f3d4;
}

.homepage-works-instro-text-title-line-down {
  height: 10px;
  width: 200px;

  margin: 0 auto;
}

.homepage-works-instro-text-title-line-down div {
  float: right;
  width: 50%;
  height: 15px;

  border-bottom: solid 2px #08f3d4;
}

.homepage-works-instro-text-context {
  padding: 30px;
  color: rgba(161, 161, 161, 0.671);
}

.fixbackground {
  /* background-color: rgb(102, 184, 205);
   */
  background-color: #0068a8;
  /* background-attachment: fixed; */
  padding-bottom: 100px;
}

.header-con {
  display: flex;
  position: relative;
  width: 100%;
  /* padding-top:100px ; */
  /* height: 100vh; */
  background-size: cover;
}

.title-home-con {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-title-home {
  width: 45%;
  /* margin: 0 auto; */
}

.left-title-home-img-outer {
  /* height: 1000px; */
  margin-bottom: 200px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  width: fit-content;
  width: 90%;
  margin: 0px auto;
  /* background-color: rgba(255, 255, 255, 0.091); */
  padding: 20px;
  border-radius: 20px;
}

.right-title-home-con text {
  font-size: 20px;
  color: plum;
}

.left-title-home-img-size {
  width: 80%;
  float: right;
}

.left-title-home-img-size-position {
  height: 100px;
}

.left-title-home-img {
  width: 100%;

  /* margin-top: 100px; */
  /* margin: 20px auto; */
  /* margin-left:200px ; */
}

.right-title-home {
  width: 45%;
}

.right-title-home-center {
  margin-top: 15rem;
  height: 500px;

  margin-bottom: 50px;
}

.right-title-home-title {
  color: #ffffff;
  font-size: 40px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.right-title-home-con {
  width: 100%;
  padding-right: 10%;
  color: #ffffff;
  font-size: large;
  font-weight: 500;
  text-align: justify;
}

.homepage-quick-entry {
  width: 80%;
  margin: 50px auto;
}

.homepage-quick-entry {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-quick-entry-depart-text {
  width: 100%;
  text-align: center;
}

.homepage-quick-entry-pridict {
}

.homepage-quick-entry-depart {
  width: 32%;
}

.homepage-quick-entry-depart-title {
  font-size: 30px;
}

.homepage-quick-entry-depart-con {
  margin: 20px;
  font-size: 18px;
  text-align: justify;
}

.homepage-quick-entry-depart-btn {
  width: 100%;
}

.homepage-quick-entry-depart-btn-con {
  float: right;
  font-size: medium;
  margin: 0px 30px;
  border: none;
  background-color: #7892ad;
  color: white;
  font-weight: 600;
}

.right-title-home Button {
  border-radius: 10px;
  color: white;
  background-color: cadetblue;
  border: none;
  float: right;
  margin: 40px 100px;
  font-weight: 600;
  height: 50px;
  width: 130px;
}

.right-other-enter {
  /* margin: 10px 10%; */

  /* padding-top: 10px; */
  /* padding-bottom: 70px; */
  border-radius: 20px;
  /* border: dotted 2px white; */
}

.right-other-enter Button {
  float: right;
  margin: 0 40px;
  border-radius: 10px;
  font-size: large;
  width: 100px;
  height: 50px;
  background-color: lightseagreen;
  color: linen;
}

.home-quick-enter {
  width: 100%;
}

.right-other-enter {
  width: 49% !important;
  float: left;
  padding: 20px;
}

.home-quick-enter .right-other-enter-con {
  padding: 20px;
  /* color: white; */
  font-size: large;
  font-weight: 500;
  text-align: justify;
  width: 100%;
}

.right-other-enter-con-button-context {
  margin: 20px;
  /* border-radius: 20px; */
  /* border: 2px dotted gray; */
}

.right-other-enter-con-title {
  width: 100%;
  text-align: center;
  font-size: x-large;
  margin-bottom: 10px;
}

.home-details-title {
  /* margin-top: 200px; */
  width: 100%;
  text-align: center;
  font-size: xx-large;
}

.home-display-position {
  height: 500px;
}

.home-display-part {
  /* margin-top: 100px;
  padding-top: 100px; */
}

.home-details-departs-text {
  float: left;
  width: 75%;
}

.home-details-departs-text-con {
  padding: 50px;
  text-align: justify;
}

.home-details-departs {
  width: 80%;
  margin: 10px auto;
  margin-top: 10px;
  margin-bottom: 200px;
  height: 100px;
  padding-top: 50px;
}

.home-details-departs-graph {
  width: 20%;
  float: left;
}

.home-details-departs-graph img {
  width: 100%;
}

.title-home-con-home-uper {
  width: 100%;
  margin-top: 200px;
}

.home-uper {
  margin: 0 auto;
}

.home-uper-down {
  width: 100%;
}

.home-uper-down-img-size {
  width: 60%;
  margin: 20px auto;
}

.home-uper-down-img {
  width: 100%;
}

.home-uper {
  width: 80%;
  margin: 0 auto;
  text-align: justify;
}

.home-uper-home-title {
  width: 100%;
  text-align: center;
  font-size: 40px;
  color: white;
  margin-bottom: 10px;
  font-weight: 700;
}

.home-uper-home-title-context {
  color: white;
  width: 100%;
  text-align: justify;
  font-size: 20px;
  padding: 10px 0px;
}

.home-uper-down-button {
  position: fixed;
  bottom: 50px;
  left: 100px;
  width: 90%;
}

.home-uper-down-button Button {
  float: left;
  margin: 0 40px;
  border-radius: 10px;
  font-size: large;
  width: 100px;
  height: 50px;
  background-color: lightseagreen;
  color: linen;
}
