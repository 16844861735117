.serverhome-body {
  width: 100%;
  height: fit-content !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.function-select {
  width: 95%;

  background-color: white;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* .function-select :hover{
box-shadow: 1px 1px 1px  ;
} */
.serverhome-body-service {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
}

@keyframes move {
  0% {
  }

  100% {
    box-shadow: 2px 2px 10px gray;
  }
}
.function-select-img-outer {
  width: 100%;
  /* margin: 20px auto; */
  display: table;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.function-select-left-img {
  width: 100%;

  /* display: table-cell; */
  margin-top: 20px;
}

.function-select-title {
  /* width: 60%; */
  text-align: center;
  font-size: xx-large;
  font-weight: 700;
  margin: 40px auto;
}

.function-select-con-way {
  /* border: 1px solid rgb(226, 224, 224); */
  /* border-radius: 10px; */
  height: 560px;
  padding: 5px;
  width: 350px;
  margin: 0 auto;
  /* border-radius: 20px; */
  /* border:5px rgba(173, 173, 173, 0.555) solid; */
  /* box-shadow: 1px 1px 10px gray; */
}

/* .function-select-con:hover{
  width: 30%;
  margin: 0 auto;
  animation: move 1s linear infinite alternate;
  
} */
.function-select-img-outer img {
  margin: 0 auto;
}
.function-select-context {
  /* border-radius: 10px; */
  /* border:rgb(223, 223, 223) 1px solid; */
  text-align: justify;
  padding: 10px;
  /* margin: 0 auto; */
  height: 190px;
  margin-top: 5px;
  /* padding: 20px; */
  width: 100%;
}

.function-select-title-inner-up {
  height: 20px;
  width: 100%;
}

.function-select-title-inner-up div {
  height: 18px;
  width: 50%;
  float: left;
  border-bottom: 2px solid #08f3d4;
}

.function-select-title-inner-down {
  width: 100%;
  height: 20px;
}

.function-select-title-inner-down div {
  height: 18px;
  width: 50%;
  float: right;
  border-top: 2px solid #08f3d4;
}

.Result-Result-body-Breadcrumb {
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(248, 248, 248);
}

.Breadcrumb-Item-text {
  font-size: 16px;
}

.title-serverHome-deeplearn {
  margin: 50px auto;
  font-size: x-large;
  text-align: center;
  font-weight: 800;
}

.function-select-button-out {
  width: 100%;
  display: flex;
}

.function-select-button {
  width: 95%;
  height: 40px;
  background-color: #1e70f3d0;
  color: white;
  margin: 10px auto;
  border-radius: 10px;
}
