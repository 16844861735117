.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: rgb(255, 255, 255);
}

.ant-layout-header {
  background-color: white;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgb(255, 255, 255);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.home-depart-outer {
  width: 70%;
}

.home-head {
  width: 100%;
  height: 300px;
  /* background-image: url("../constants/img/1.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
}

@-webkit-keyframes mymove {
  50% {
    border-bottom: 15px solid lightblue;
  }
}

@keyframes mymove {
  50% {
    border-bottom: 15px solid lightblue;
  }
}

.home-head-binary-title {
  width: fit-content;
  padding-left: 50px;
  padding-top: 40px;
  font-size: 50px;
  font-weight: bolder;
  color: #1808f3;
  float: left;
  text-align: left;
}

.home-head-memu-bar {
  padding-top: 20px;
  padding-left: 30px;
  width: 600px !important;

  float: right;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: none;
}

.commen-Menu-Item {
  font-size: 16px;

  background-color: black !important;

  color: white;
}

.ant-menu-item-selected {
  font-size: 16px;
}

.JobHome-class {
  width: 100%;
  margin: 0 auto;
}

.ant-layout-content {
  width: 100% !important;
}

.Menu-header-home-item.ant-menu-submenu {
  background-color: black !important;
}

.ant-menu-submenu-horizontal {
  background-color: black !important;
  /* width: 600px; */
}

.Menu-header-home-item-SubMenu {
  background-color: black !important;
  color: white;
  font-size: 16px;
}

/* .Menu-header-home-item-SubMenu ::after{
  width: 0.3s;
  transition :border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
} */
/* .ant-menu-item .ant-menu-item-pupop::before{
  background-color: white;
} */
.SubMenu-Menu-ItemGroup-Item .ant-menu-item {
  color: black;
  margin: 0px !important;
  padding: 0px !important;
}

.My-Menu-ItemGroup .ant-menu-item-group-title {
  margin: 0px !important;
  padding: 0px !important;
  width: 0px !important;
  height: 0px !important;
  /* border-radius: 15px; */
}

.My-Menu-ItemGroup {
  background-color: black;
  border-radius: 15px;
}

.My-Menu-ItemGroup .ant-menu-item-group-list {
  background-color: black;
  border-radius: 5px;
}

.My-Menu-ItemGroup .ant-menu-item-group-list .SubMenu-Menu-ItemGroup-Item-up {
  background-color: black;
  color: white;
  font-weight: 500;
  font-size: 16px;

  margin: 0px !important;
  border-left: black 8px solid;
  text-align: left;
  align-items: left;
  padding-left: 10px !important;
}

.My-Menu-ItemGroup .ant-menu-item-group-list .SubMenu-Menu-ItemGroup-Item-down {
  color: white;
  font-weight: 500;
  background-color: black;
  font-size: 16px;

  margin: 0px !important;
  border-left: black 8px solid;
  padding-left: 10px !important;
}

.My-Menu-ItemGroup .ant-menu-title-content {
  width: 100%;
  text-align: left;
}

.My-Menu-ItemGroup .ant-menu-item-selected {
  background-color: black !important;
  border-left: rgb(123, 202, 255) 8px solid !important;
}

.ant-menu-vertical {
  margin: 0px;
  padding: 0px;
  height: fit-content;
  background-color: black;
}

.ant-menu-submenu-placement-bottomLeft {
  margin: 0px !important;
  padding: 0px !important;
}

#rc-menu-uuid-77297-1-2-popup {
  margin: 0px !important;
  padding: 0px !important;
}

.home-head-introduction {
  width: 600px;
  font-size: small;
  color: #ffffffd8;
  text-align: center;
}

.ServerPage-tabs {
  margin-top: 30px;
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
}

.tabpane-part {
  background-color: gray;
}

.ant-tabs-nav-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
}

.ServerPage-TabPane {
}

.ServerPage-tabs .ServerPage-tabs-TabPane .ant-tabs-tab {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ServerPage-tabs {
  margin: 50px auto;
}

.logo-welab {
  float: left;

  font-size: 30px;
  font-weight: 800;
  text-align: right;
  background-color: black !important;
  color: white;
}

.Menu-header-home {
  width: 100%;
  background-color: black !important;
  z-index: 999;
  margin: 0 auto;
}

.ant-layout .ant-layout-header {
  z-index: 999;
  background-color: black !important;
}

.Menu-header-home-outer-inite {
  background-color: black !important;
  z-index: 999;

  position: fixed;
  width: 100%;
}

.Menu-header-home-item {
  height: 50px;

  width: 850px !important;
  border: none;

  z-index: 999;
  background-color: black !important;

  float: right;
}

.Menu-header-home-outer-in-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0 50px;
  background-color: black !important;
  z-index: 999;
}

.headerCon-tab {
  margin: 5px;
  font-weight: 300;
}

.ant-menu-title-content .headerCon-tab {
  font-weight: 300;
}

.ant-layout .ant-layout-header {
  padding: 0;
  height: 55px;
}

.SubMenu-title-text {
  font-size: "20px";
}

.headCon-tab-text {
  margin-top: 10px;
}

.submit-depart-pages {
  width: 70% !important;
  margin: 100px auto;
}
.footer-home-inner-contact {
  text-align: right;
  color: white;
}

.footer-home {
  background-color: black;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.footer-home-inner {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  /* height: 200px; */
}

.Global-isitors-earth {
}

.context-us-footer {
  width: 50%;
  color: white;
  /* border-right: white 1px solid; */
  padding: 20px;
}

.submit-depart-pages-unique {
  width: 80% !important;
  margin: 100px auto;
}

.Result-Result-body-Breadcrumb-button {
  /* margin-bottom: 10px; */
  margin-top: -5px;
  float: right;
  color: rgb(42, 42, 42);
}

.context-us-footer-quick-link {
  width: 50%;
  text-align: left;
  float: right;
  margin-top: 40px;
}
.context-us-footer-about {
  /* width: 80%; */
}
.home-logo img {
  width: 25%;
  margin: 5px;
}
/* .home-logo{ */
/* width: 40% ; */
/* } */
