.Details-titles-small {
    font-size: x-large;
}

.result-body-DrugId {
    height: 100px;
    font-size: xx-large;
    font-weight: 500;
    border-radius: 10px 10px 10px 10px;
    /* border:gray 1px solid */
    padding: 15px;
    margin-bottom: 40px;
    background-color: #cce5ff;
    /* box-shadow:10px 10px 10px  lightgray ; */
}

.result-body-Details-body {
    margin-bottom: 50px;
}

.smiles-img {
    width: 200px;
}