.serverPageModel-inputValue Input {
  width: 60%;
  border-radius: 5px;
  /* border: 3px solid rgb(97, 200, 166); */
  margin-left: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
  /* float: left; */
}

.serverPageModel-inputValue Button {
  margin-left: 5px;
  border-radius: 5px;
  width: fit-content;
}

.serverPageModel-body {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
}

.serverPageModel-inputValue {
  width: 100%;
  /* float: left; */
}

.serverPageModel-inputValue div {
  /* float: left; */
  margin: 20px;
  padding: 5px;
  width: 15%;
  text-align: right;
}

.serverPageModel-inputValue-footer Button {
  float: left;
  margin: 20px;
  /* border: 3px solid rgb(137, 233, 201); */
  /* background-color: rgb(137, 233, 201); */
  border-radius: 5px;
  /* color: white; */
  /* font-weight: 600; */
  /* width: fit-content; */
  width: 100px;
}

.serverPageModel-button {
  width: 50%;
  margin: 20px auto;
}

.serverPageModel-button Button {
  margin: 20px;
  border: 3px solid rgb(137, 233, 201);
  background-color: rgb(137, 233, 201);
  border-radius: 10px;
  color: white;
  font-weight: 600;
}

.ant-list-header {
  padding: 0;
  padding-right: 0px;
  padding-left: 0px;
}

.ant-list-bordered .ant-list-header {
  padding-right: 0px;
  padding-left: 0px;
}

.ant-list-bordered {
  border-radius: 10px;
}

.serverPageModel-title {
  font-size: x-large;
  width: 100%;
  background-color: #bee5eb;
  padding: 20px;
  /* border-radius: 10px; */
}

.serverPageModel-description {
  width: 100%;
  background-color: #bee5eb;
  padding: 20px;
  /* border-radius: 10px; */
}

.serverPageModel-body {
  width: 80%;
  margin: 50px auto;
}

.tips-serverPageModel {
  float: left;
  width: 100%;
  /* height: 130px; */
  border-radius: 10px;
  border: dotted 4px rgb(163, 163, 163);
  margin: 20px auto;
  padding: 30px;
  color: rgb(163, 163, 163);
  font-weight: 700;
}
.serverPageModel-body-inside {
  width: 100%;
  /* margin: 10px auto; */

  margin-top: 50px;
}
.panel-serverPageModel {
  width: 100%;
  margin: 0 auto;
}
.button-serverPageModel-last {
  height: 70px;
}
#Typography-serverPageModel {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.Typography-serverPageModel-Anchor {
  float: right;
  margin-right: 20px;
  /* height: fit-content; */
}

.input-tips {
  width: 100%;
  text-align: left;
  padding-right: 20px;
  color: rgb(105, 105, 105);
}

.input-select-model {
  width: 100%;
}

.input-select-model .ant-select-selector {
  width: 100% !important;
}
